import React, { Component } from "react";
import Counter from "./counter";
import counter from "./counter";
// rpce

class counters extends Component {
  //   state = {
  //     counters: [
  //       { id: 1, value: 4 },
  //       { id: 2, value: 0 },
  //       { id: 3, value: 0 },
  //     ],
  //   };

  //   handleIncrement = (counter) => {
  //     const counters = [...this.state.counters];
  //     const index = counters.indexOf(counter);
  //     counters[index] = { ...counter };
  //     counters[index].value++;
  //     this.setState({ counters });
  //   };

  //   handleReset = () => {
  //     const counters = this.state.counters.map((c) => {
  //       c.value = 0;
  //       return c;
  //     });
  //     this.setState({ counters });
  //   };

  //   handleDelete = (counterId) => {
  //     const counters = this.state.counters.filter((c) => c.id !== counterId);
  //     this.setState({ counters });
  //   };

  render() {
    // this.props.onReset
    const { onReset, counters, onDelete, onIncrement, onDecrement } =
      this.props;
    return (
      <div>
        <button
          onClick={onReset} //this.handleReset
          className="btn btn-primary btn-sm m-2"
        >
          Reset
        </button>
        {counters.map((counter) => (
          <Counter
            key={counter.id}
            onDelete={onDelete}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            counter={counter}
          />
          //state > props
          // value={counter.value}
          // id={counter.id}
          // selected={counter.selected}
        ))}
      </div>
    );
  }
}

export default counters;
