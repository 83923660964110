import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import NavBar from "./components/navbar";
import Movies from "./components/movies";
import Customer from "./components/customer";
import NotFound from "./components/notfound";
import "./App.css";

class App2 extends Component {
  render() {
    return (
      <main className="container">
        <NavBar />
        <Switch>
          <Route path="/movies" component={Movies}></Route>
          <Route path="/customer" component={Customer}></Route>
          <Route path="/not-found" component={NotFound}></Route>
          <Redirect from="/" exact to="/movies" />
          <Redirect to="/not-found" />
        </Switch>
      </main>
    );
  }
}

export default App2;
