import React, { Component } from "react";

class qa1 extends Component {
  render() {
    return (
      <div>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
      </div>
    );
  }
}
export default qa1;
