import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Counter from "./components/counter";
import Counters from "./components/counters";
import Movies from "./components/movies";
import ToDo from "./components/toDo";
import Qa1 from "./qa1";
import Counter_ex from "./components/counter_ex";
import App2 from "./app2";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App2 />
  </BrowserRouter>
  // <React.StrictMode>

  // </React.StrictMode>
  // <ToDo />
);
//   <Movies />
//<Counter />
// <App />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
