import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

// state less funtional component
const NavBar = (props) => {
  return (
    <nav className="navbar navbar-dark bg-dark">
      <a className="navbar-brand" href="#">
        Navbar{""}
        <span className="badge badge-pill badge-secondary"></span>
      </a>
      <div className="navbar-nav">
        <NavLink className="nav-item nav-link active" to="/movies">
          {" "}
          Movies
        </NavLink>
        <NavLink className="nav-item nav-link " to="/customer">
          {" "}
          Customers
        </NavLink>
        <NavLink className="nav-item nav-link " to="/not-found">
          NotFound
        </NavLink>
      </div>
    </nav>
  );
};
// {props.totalCounters}

// class NavBar extends Component {
//   render() {

//   }
// }

export default NavBar;
