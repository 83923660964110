import React from "react";
import Like from "./common/like";
// rfce
const MoviesTable = (props) => {
  const { movies, onDelete, onLike, onSort } = props;
  return (
    <table className="table">
      <thead>
        <tr>
          <th onClick={() => onSort("title")}>Title</th>
          <th onClick={() => onSort("genre.name")}>Genre</th>
          <th onClick={() => onSort("numberInstock")}>Stock</th>
          <th onClick={() => onSort("dailyRentRate")}>Rate</th>
          <th>-</th>
          <th>A</th>
        </tr>
      </thead>
      <tbody>
        {movies.map((movi) => (
          <tr key={movi._id}>
            <td>{movi.title}</td>
            <td>{movi.genre.name}</td>
            <td>{movi.numberInstock}</td>
            <td>{movi.dailyRentRate}</td>
            <td>
              <Like liked={movi.liked} onClick={() => onLike(movi)} />
            </td>
            <td>
              <button
                onClick={() => onDelete(movi)}
                className="btn btn-danger btn-sm"
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MoviesTable;
