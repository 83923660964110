const movies = [
  {
    _id: "001",
    title: "ATerminator",
    genre: { _id: "001", name: "Action" },
    numberInstock: "3",
    dailyRentRate: "3.0",
    liked: true,
  },
  {
    _id: "002",
    title: "BTerminator",
    genre: { _id: "001", name: "Action" },
    numberInstock: "4",
    dailyRentRate: "3.0",
  },
  {
    _id: "003",
    title: "CTerminator",
    genre: { _id: "002", name: "Comedy" },
    numberInstock: "6",
    dailyRentRate: "3.0",
  },
  {
    _id: "004",
    title: "XTerminator",
    genre: { _id: "003", name: "Thriller" },
    numberInstock: "7",
    dailyRentRate: "3.0",
  },
  {
    _id: "005",
    title: "ZTerminator",
    genre: { _id: "002", name: "Comedy" },
    numberInstock: "2",
    dailyRentRate: "3.0",
  },
  // {
  //   _id: "002",
  //   title: "Terminator",
  //   genre: { _id: "100", name: "Action" },
  //   numberInstock: "5",
  //   dailyRentRate: "3.0",
  // },
  // {
  //   _id: "003",
  //   title: "Terminator",
  //   genre: { _id: "100", name: "Action" },
  //   numberInstock: "5",
  //   dailyRentRate: "3.0",
  // },
  // {
  //   _id: "004",
  //   title: "Terminator",
  //   genre: { _id: "100", name: "Action" },
  //   numberInstock: "5",
  //   dailyRentRate: "3.0",
  // },
];

export function getMovies() {
  return movies;
}

export function getMovie(id) {
  return movies.find((m) => m._id === id);
}
