import React, { Component, useState, useEffect } from "react";

// class Customer extends Component {
//   render() {
//     return <h1>Customer here</h1>;
//   }
// }

// export default Customer;

function Customer() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((res) => res.json())
      .then((result) => {
        setPosts(result);
      });
  }, []);

  return (
    <ul>
      {posts.map((post) => (
        <li key={post.id}>
          {post.id} | {post.name}{" "}
        </li>
      ))}
    </ul>
  );
}

export default Customer;
